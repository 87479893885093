import { graphql } from 'gatsby'
import React, { memo } from 'react'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import { Desktop } from './utils/breakpoints';
function Info({ info }) {

    return (
        <div className='pt160 m-pt0 '>
            <Desktop >

                <p className='max-700 mt0 '>
                    {info.introduction}
                </p>
            </Desktop>
            <div className='flex justify-between flex-wrap m-flex-reverse'>
                <div>
                    <p className='title bb1 m0 mb20 mt20 pb10 m-btw1 m-bbw1  m-p20 m-mb0'>how it works </p>
                    <div className='m-p20'>
                        <Steps steps={info.steps} />
                    </div>
                </div>
                <div>
                    <div className='max-200 w-100 m-mwa'>
                        <p className='title bb1 m0 mb20 mt20 pb10  m-p20 m-mt0  m-btw1 m-bbw1 m-mb0'>what we do </p>
                        <div className='m-p20'>
                            <HighlightedItem title='services '>
                                {info.services}
                            </HighlightedItem>
                            <HighlightedItem title='the division devstack '>
                                {info.devStack}
                            </HighlightedItem>
                            <HighlightedItem title='other technologies'>
                                {info.otherTechnology}
                            </HighlightedItem>
                            <HighlightedItem title='team'>
                                {info.team}
                            </HighlightedItem>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


function Steps({ steps }) {
    return (
        <div className='max-300 mr20 '>
            {steps.map(({ title, content }, index) => {
                return (
                    <div className='mb30' key={index}>
                        {title}
                        <br />
                        <br />
                        <ReactMarkdown>
                            {content}
                        </ReactMarkdown>
                    </div>
                )
            })}

        </div>
    )
}

function HighlightedItem({ title, children }) {
    return (
        <>
            <div className='m0 ' >
                {title}
                <br />
                <br />
                <ReactMarkdown className='m0 pm0'>
                    {children}
                </ReactMarkdown>
            </div>
            <br />
        </>
    )
}

export const query = graphql`
    fragment Step on DatoCmsStep {
        title
        content
    }

    fragment Info on DatoCmsInformation{
        devStack
        otherTechnology
        team
        services
        introduction
        steps{
            ...Step
        }
    }
`

export default memo(Info)