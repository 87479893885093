import React from 'react'
import Tab from './tab'
import Hero from '../logos/hero'
import TabTitle from './tabtTitle'
import Contact from './contact'
import { SECTION_NAMES } from './context/tabs'
import Projects from './projects'
import Info from './information'
import { useSpring, animated } from 'react-spring'
import Pill from './pill'

export default function DesktopHome({ data, projects, finishedAnimating }) {

    const [pillStyle, api] = useSpring(() => ({
        opacity: (finishedAnimating ? 0 : 1),
        duration: 250,
    }))



    function animatePill(api) {
        return (isOpening) => {
            api.start({
                delay: isOpening ? 250 : 0,
                opacity: (isOpening && finishedAnimating) ? 1 : 0
            })
        }
    }

    return (
        <>
            <div className="flex tabs initial--state w-100vw relative">
                <Tab
                    tabName={SECTION_NAMES.HERO}
                    ratio={2}
                    runIsOnly={animatePill(api)}
                    name={
                        <Hero className='pr40' />
                    }
                    title={
                        <TabTitle>division</TabTitle>
                    }>

                    <div className='flex h-100   '>
                        <animated.div style={pillStyle} className={' absoulte left-0 w-100vw top-0 h-100vh video--container under-video'}>
                            <Pill />
                        </animated.div>
                        <div className='flex flex-col justify-between'>
                            <p className='pt160 m-pt0 h-100 flex flex-col mt0 mb20 ' dangerouslySetInnerHTML={{ __html: data.info.websiteCaption }}/>
                            <Contact contact={data.contact} />
                        </div>
                    </div>

                </Tab>

                <Tab
                    tabName={SECTION_NAMES.PROJECTS}
                    ratio={4}
                    name={`projects (${data.allDatoCmsProject.total})`}
                    title={<TabTitle>projects</TabTitle>} >
                    <Projects projects={projects} />
                </Tab>

                <Tab
                    tabName={SECTION_NAMES.INFO}
                    ratio={6}
                    name='information'
                    title={
                        <TabTitle>
                            information
                        </TabTitle>
                    } >
                    <Info info={data.info} />
                </Tab>
            </div>
        </>

    )
}



