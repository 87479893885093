import React, { createContext, useCallback, useContext, useReducer } from 'react'
import omit from 'lodash.omit'

export const SECTION_NAMES = {
    HERO: 'hero',
    PROJECTS: 'projects',
    INFO: 'info'
}

const defaultState = {
    HERO: 'hero',
}

const TabContext = createContext(defaultState)


function reducer(state, action) {
    switch (action.type) {
        case 'open':
            return openTab(state, action.payload)
        case 'close':
            return closeTab(state, action.payload, Object.keys(state).length)
        default:
            return state
    }
}

function closeTab(state, name, length) {
    switch (true) {
        case (SECTION_NAMES[name] === SECTION_NAMES.HERO && length === 1):
            return { [SECTION_NAMES.PROJECTS.toUpperCase()]: SECTION_NAMES.PROJECTS }
        case length === 1:
            return { [SECTION_NAMES.HERO.toUpperCase()]: SECTION_NAMES.HERO }
        default:
            return omit(state, [name])
    }
}

function openTab(state, name) {
    return { ...state, [name]: SECTION_NAMES[name] }
}

export default function Tabs({ children }) {
    const [openedTabs, dispatch] = useReducer(reducer, defaultState)

    return (
        <TabContext.Provider value={{ openedTabs, dispatch }}>
            {children}
        </TabContext.Provider>
    )
}

function useTabContext() {
    const context = useContext(TabContext)
    return context
}


export function useTab(tabName) {
    tabName = tabName.toUpperCase()

    const { openedTabs, dispatch } = useTabContext()

    const setOpen = (flag) => {
        dispatch({ type: (flag ? 'open' : 'close'), payload: tabName })
    }
    
    return [(tabName in openedTabs), setOpen, Object.keys(openedTabs).length]
}


export function useMassOpen() {
    const { dispatch } = useTabContext()
    const massOpen = useCallback(() => {
        dispatch({ type: 'open', payload: SECTION_NAMES.PROJECTS.toUpperCase() })
        dispatch({ type: 'open', payload: SECTION_NAMES.INFO.toUpperCase() })
    }, [dispatch])
    return [massOpen]
}

