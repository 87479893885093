import * as yup from 'yup'
const REQUIRED_ERROR = `Please enter a valid `
const URL_ERROR = "Please enter a valid Url"
const EMAIL_ERROR = "Please enter a valid email"
const OPTION_ERROR = "Please select a valid option"
export const budgetValues = ["up to $10k", "$10k to $15k", "$15k to $25k", "$25k to $35k", "$35k plus"] 
export const yesNo = ["Yes", "No"]

const string = yup.string().default("")

const schema = {
    "form-name": yup.string().default("quote").matches(/quote/),
    name: string.required(createRequiredError('name')),
    email: string.required(createRequiredError('email')).email(EMAIL_ERROR),
    position: string,
    phone: string,
    businessName: string.required(createRequiredError('Business Name')),
    website: string.url(URL_ERROR),
    city: string,
    country: string,
    start: string,
    end: string,
    hasDesign: yup.mixed().default(yesNo[0]).oneOf(yesNo, OPTION_ERROR),
    figmaLink: string.url(URL_ERROR),
    referralInfo: string,
    budget: yup.mixed().default(budgetValues[0]).oneOf(budgetValues, OPTION_ERROR),
    additionalInfo: string
}

export const formSchema = yup.object().shape(schema)

function createRequiredError(name: string): string {
    return REQUIRED_ERROR + name
}