import React from 'react'
import { useState } from 'react'

import useMeasure from 'react-use-measure'

export default function Dropdown({ children, name, inital = false }) {

    const [ref, { height }] = useMeasure()
    const [open, setOpen] = useState(inital)

    return (
        <div>
            <button className='w-100 bt1 p20' onClick={() => setOpen((state) => !state)}>
                {open ? < Open name={name} /> : <Closed name={name} />}
            </button>
            <div className='overflow-hidden transition-height' style={{ height: (open ? height : '0px') }}>
                <div ref={ref} className=' pt0' >
                    {children}
                </div>
            </div>
        </div>
    )
}


function Open({ name }) {
    return (
        <div className='flex justify-between font-med'>
            {name}
            <svg width="20" height="20" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="7.5" cy="7.5" r="7" stroke="#3A3A3A" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.47483 7.41421L9.2426 9.18198L9.94971 8.47487L7.47483 6L4.99996 8.47487L5.70707 9.18198L7.47483 7.41421Z" fill="#3A3A3A" />
            </svg>


        </div>
    )
}

function Closed({ name }) {
    return (
        <div className='flex justify-between font-med'>
            {name}
            <svg width="20" height="20" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="7.5" cy="7.5" r="7" stroke="#3A3A3A" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.47487 7.76777L5.70711 6L5 6.70711L7.47487 9.18198L9.94975 6.70711L9.24264 6L7.47487 7.76777Z" fill="#3A3A3A" />
            </svg>


        </div>
    )
}