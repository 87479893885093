import React from "react"
import { GatsbyImage } from 'gatsby-plugin-image'
import { memo } from 'react'

const Projects = ({ projects }) => {
  return (
    <div className='projects pt160 m-pt0  '>
      {projects.map((project, index) => {
        var timer;
        var count = 2;
        const playGallery = (id) => {
          return () => {
            timer = setInterval(function () {
              var current = document.querySelector('#' + id + ' .image.active');
              if (count < project.featureGallery.length) {
                count++;
              } else {
                count = 1;
              }
              current?.classList.remove('active');
              document.querySelector('#' + id + ' .image:nth-child(' + count + ')').classList.add('active');
            }, 700);
          }
        }

        const stopTimer = () => {
          clearInterval(timer);
        }

        return (
          <div id={project.id} className="single--project mb20" onMouseEnter={playGallery(project.id)} onMouseLeave={stopTimer} role='presentation' key={index}>
            <a className='link' href={project.websiteLink} rel="noreferrer" target='_blank'>
              <div className='ratio-2-1 pos-rel overflow-hidden project--gallery'>
                {project.featureGallery.map((image, index) => {
                  return (
                    <GatsbyImage key={index} className={'bg-image bg-grey image ' + (index === 0 ? 'active' : 'm-hide')} image={image?.gatsbyImageData} alt={project.title} />
                  )
                })}
              </div>
              <div className='project--info flex pt10'>
                <div className='w-50'>
                  client<br />
                  {project.client}
                </div>
                <div className='w-50'>
                  tech<br />
                  {project.tech}
                </div>
              </div>
            </a>
          </div>
        )
      })}
    </div>
  )
}



export default memo(Projects)
