import React from 'react'
import { Formik } from 'formik'
import { useState } from 'react'
import { formSchema } from './form'
import { ErrorState, FormState, SubmittedState } from './formStates'
import { CSSTransition, SwitchTransition } from 'react-transition-group'

type FormStates = "error" | "form" | "submitted"

type FormStatesProps = {
    currentState: FormStates;
}

const initialValues = formSchema.getDefaultFromShape();
type InitialValues = typeof initialValues

export function ContactForm() {

    const [formState, setFormState] = useState<FormStates>("form")

    async function submit(values: InitialValues): Promise<void> {
        try {
            const result = await fetch('/', {
                method: "POST",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: encodeData(values).toString()
            })

            if (result.status > 299) {
                throw new Error('Non 200 status code ' + result.status)
            }

            setFormState('submitted')

        } catch (e) {
            setFormState('error')

            if (e instanceof Error) {
                console.error(e)
            } else {
                console.warn(e)
            }
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={submit}
            validateOnBlur={true}
            validateOnChange={false}
            validationSchema={formSchema}
        >
            <div className='bl1 br1 h-100 overflow-y-scroll w-100 m-b-none'>
                <SwitchTransition>
                    <CSSTransition timeout={500} key={formState} classNames='fade' >
                        <FormStates currentState={formState} />
                    </CSSTransition>
                </SwitchTransition>
            </div>
        </Formik>
    )
}

function FormStates({ currentState }: FormStatesProps) {
    switch (currentState) {
        case 'error':
            return <ErrorState />
        case 'form':
            return <FormState />
        case 'submitted':
            return <SubmittedState />
    }
}


function encodeData(values: InitialValues): URLSearchParams {
    const searchParams = new URLSearchParams()
    Object.entries(values).forEach(([key, value]) => {
        if (value) {
            searchParams.append(key, value)
        }
    })

    return searchParams
}

