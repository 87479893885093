import React from 'react'
import { ErrorMessage, ErrorMessageProps, Field } from 'formik'

type FormInput = {
    name: string;
}

type TextInput = FormInput & {
    required?: boolean;
    type: "text" | "email";
    showTitle?: boolean;
}

type SelectInput = FormInput & {
    options: string[];
    type: "radio";
}

export function FormField({ name, type, required = false, showTitle = true }: TextInput) {
    return (
        <div>
            <label className='white-50 text-form-control flex flex-col'>
                {showTitle ? `${name}${required ? '*' : ''}` : ``}
                <Field className='text-input' name={name} type={type} required={required} />
            </label>
            <StyledErrorMessage name={name} />
        </div >
    )
}

export function SelectField({ name, type, options }: SelectInput) {
    return (
        <div>
            <div className='grid gap-y-10 grid-cols-1'>
                {options.map((option) => {
                    return (
                        <label className='flex items-center select-form-control'>
                            <Field className='select-input' name={name} type={type} value={option} />
                            <span className='white-50' >{option}</span>
                        </label>
                    )
                })}
            </div>
            <StyledErrorMessage name={name} />
        </div>
    )
}

function StyledErrorMessage({ name }: ErrorMessageProps) {
    return (
        <div className='error-message'>
            <ErrorMessage name={name} />
        </div>
    )
}