import React from 'react'
import Hero from '../logos/hero'

import { ContactForm } from './form/contactForm'
import OverlayTab from './form/overlayTab'
import ContactDetails from './contactDetails'

export default function ContactPopup({ onClick, contact }) {
    return (
        <div className='grid grid-cols-2-60 bg-dark-grey h-100vh overflow-hidden contact-overlay w-100vw'>
            <div className='flex flex-col justify-between white p20'>
                    <div style={{height:'30.5'}}>
                    <Hero color='#fff' />
                    </div>
            
                <p className='pt160 m-pt0 h-100 flex flex-col mt0 mb20 '>
                    we code websites <br /> for creative people
                </p>
                <ContactDetails contact={contact} onClick={onClick} text='return to info' />
            </div>
            <ContactForm />
            <OverlayTab title="back to division" onClick={onClick} />
        </div >

    )
}
