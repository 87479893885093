import React, { useLayoutEffect } from 'react'
import Projects from "../components/projects"
import Info from '../components/information'
import Dropdown from "./utils/dropdown"
import MobileMenu from "../components/mobileMenu"
import useMeasure from './hooks/useMeasure'
import Pill from './pill'
import ContactDetails from './contactDetails'


export default function MobileHome({ data, projects }) {


    useLayoutEffect(() => {
        const body = document.querySelector('.mobile-body')
        if (body) {
            body.style.minHeight = window.innerHeight + 'px';
        }
    }, [])
    const [ref, { height }] = useMeasure()

    return (
        <div className="mobile-body">
            <MobileMenu height={height} >
                <div className=" white" >
                    <p className="mb20 mt0" dangerouslySetInnerHTML={{ __html: data.info.websiteCaption }} />
                    <ContactDetails onClick={scrollToForm} contact={data.contact} text='get a quote' />
                </div>
            </MobileMenu>
            <div ref={ref} className="p20 flex-grow-1 flex">
                <div className='mta mba'>
                    <p className='pt160 m-pt0 h-100 flex flex-col mt0 mb20' dangerouslySetInnerHTML={{ __html: data.info.websiteCaption }} />
                    <p className='max-700 mt0'>
                        {data.info.introduction}
                    </p>
                    <div className='relative w-100 square'>
                        <Pill />
                    </div>
                </div>
            </div>
            <Dropdown name={`projects (${data.allDatoCmsProject.total})`}>
                <div className='p20'>
                    <Projects projects={projects} />
                </div>
            </Dropdown>
            <Dropdown inital={true} openOn={'contact'} name='information'>
                <Info info={data.info} />
            </Dropdown>
        </div>

    )
}

function scrollToForm() {
    document.querySelector('#form').scrollIntoView({ behavior: 'smooth' })
}
