import React from 'react'
import Layout from "../components/shared/layout"
import Seo from "../components/shared/seo"
import { graphql } from "gatsby"
import DesktopHome from '../components/desktopHome'
import MobileHome from '../components/mobileHome'
import { Desktop, Tablet } from '../components/utils/breakpoints'
import Tabs from '../components/context/tabs'
import FormFields from '../components/form/formFields'
import { animated, useSpring } from 'react-spring'
import HomeAnimation from '../components/homeAnimation'

const IndexPage = ({ data }) => {
  var projects = data.allDatoCmsProject.nodes;


  const bodyStyles = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    config: { duration: 500 },
  })

  return (
    <animated.div style={bodyStyles}>
      <Layout>
        <Seo title="Division | Coding websites for creative people" />
        <FormFields />
        <Tablet>
          <MobileHome data={data} projects={projects} />
        </Tablet>
        <Desktop>
          <Tabs>
            <HomeAnimation>
              {(finishedAnimating) => (
                <DesktopHome finishedAnimating={finishedAnimating} data={data} projects={projects} />
              )}
            </HomeAnimation>
          </Tabs>
        </Desktop>
      </Layout>
    </animated.div>
  )
}

export default IndexPage


export const query = graphql`
query HomepageQuery {
  info:datoCmsInformation {
    ...Info
    websiteCaption
  }
  contact:datoCmsInformation {
    designChecklist
    getAQuoteLink
    email
  }
  allDatoCmsProject(sort: {order: ASC, fields: position}) {
    total:totalCount
    nodes {
      title
      client
      websiteLink
      tech
      id

      featureGallery {
        gatsbyImageData(placeholder:NONE)
      }
    }
  }
}
`