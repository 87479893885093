import React, { useState,useEffect } from 'react'
import Hero from '../logos/hero'
import useMeasure from 'react-use-measure'
import { ContactForm } from './form/contactForm'

export default function MobileMenu({ children, height, }) {
    const [open, setOpen] = useState(false)
    const [ref, { height: menuHeight }] = useMeasure()

    useEffect(() => {
        if (open) {
            return document.querySelector('body').classList.add('locked')
        }
        return document.querySelector('body').classList.remove('locked')
    }, [open])

    return (
        <div ref={ref} className=" p20 pb0 sticky-top m-pb20 " >
            <div className='flex justify-between items-center relative z-99'>
                <Hero maxWidth='129px' color={(open ? '#EFEFEF' : '#3A3A3A')} />
                <button onClick={() => setOpen((state) => !state)} className={"underline " + (open ? 'white' : '')}>{!open ? `contact` : `close`}</button>
            </div>
            <div className={'fixed h-100vh bg-grey menu flex flex-wrap ' + (open ? 'open' : '')} >
                <div className='inner h-100vh overflow-y-scroll flex flex-wrap w-100'>
                    <div style={{ height: window.innerHeight - 224, paddingTop: `${menuHeight + 20}px` }} className='flex w-100 items-center p20'>
                        {children}
                    </div>
                    <div  id='form' className='white w-100 mta get-quote--tab'>
                        <div className='tab--title p20 btww1 white flex bbw1'>
                            get a quote
                            <svg className='mla mta mba' width="20" height="20" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="7.5" cy="7.5" r="7" stroke="#fff" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.47487 7.76777L5.70711 6L5 6.70711L7.47487 9.18198L9.94975 6.70711L9.24264 6L7.47487 7.76777Z" fill="#fff" />
                            </svg>
                        </div>
                        <div className='inner--form'>
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
