import React from 'react'

export default function ContactDetails({contact, onClick, text = 'get a quote' }) {
    return (
        <div className='contact--info break-word mta' >
            ready now?<br />
            a: <a href={contact.designChecklist} target='_blank' rel="noreferrer" className='ul-link'>design checklist</a><br />
            b: <button onClick={onClick} className='ul-link'>{text}</button>
            <br /><br /><br />
            need help?<br />
            <a className='ul-link' href={'mailto:' + contact.email}>{contact.email}</a>
        </div >
    )
}
