import React from 'react'
import { formSchema } from './form'

export default function FormFields() {
    return (
        <form data-netlify="true"
            name={formSchema.getDefault()['form-name']}
            method="post">
            {
                Object.keys(formSchema.getDefault()).map((name) => {
                    if (name === 'form-name') {
                        return <input readOnly hidden={true} name={name} value={formSchema.getDefault()['form-name']} />
                    }
                    return <input readOnly hidden={true} name={name} />
                })}
        </form>
    )
}
