import { useRef, useMemo, useEffect } from 'react'

export default function useRunOnlyTabOpen(open, length, runIsOnly) {
    const isFirstRun = useRef(true)

    const onlyTabOpen = useMemo(() => {
        return (open && length === 1)
    }, [open, length])

    useEffect(() => {
        if (!isFirstRun.current) {
            runIsOnly(onlyTabOpen)
        }
        isFirstRun.current = false

    }, [onlyTabOpen, runIsOnly])
}
