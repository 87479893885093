import  { useEffect, useRef, useState } from 'react'

export default function useMeasure() {

    const [height, setHeight] = useState(0)
    const ref = useRef(null)
    useEffect(() => {
        if (ref.current) {
            setHeight(ref.current.offsetHeight)
        }
    }, [])
    return [ref, { height }]
}
