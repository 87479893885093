import React, { useState } from 'react'
import ContactPopup from './contactPopup'
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { Portal } from './utils/portal';
import ContactDetails from './contactDetails';

const Contact = ({ contact, }) => {
    const [open, setOpen] = useState(false)
    return (
        <>
            <ContactDetails onClick={() => setOpen(true)} contact={contact} />
            <SwitchTransition>
                <CSSTransition key={open} classNames="fade" timeout={500}>
                    <>
                        {open ? <Portal node={(typeof window !== 'undefined' && document.querySelector('#___gatsby'))}>
                            <ContactPopup contact={contact} onClick={() => setOpen(false)} />
                        </Portal> : null}
                    </>
                </CSSTransition>
            </SwitchTransition>
        </>
    )
}

export default Contact

