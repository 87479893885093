import React, { useState } from 'react'
import Title from './title'
import { FormField, SelectField } from './fields'
import { Form } from 'formik'
import { yesNo, budgetValues } from './form'

export function ErrorState() {
    return (
        <div className='flex justify-center align-center h-100 m-h-100vh'>
            <h1 className='white text-16 text-center  max-600 p20'>
                An error occurred, please attempt to submit again or contact us a different way.
            </h1>
        </div>
    )
}

export function SubmittedState() {
    return (
        <div className='flex justify-center align-center h-100 m-h-100vh'>
            <h1 className='white text-16 text-center max-600 p20'>
                Thanks for submitting We'll get back to you shortly.
            </h1>
        </div>
    )
}

export function FormState() {
    return (
        <div>
            <Form data-netlify="true"
                name="quote"
                method="post">
                <input readOnly type="hidden" name="form-name" value="quote" />
                <div className='p20 pb0 m-hide'>
                    <h2 className='white match-body m0'>get a quote</h2>
                </div>
                <div className='bbw1 pb50 pt160 pl20 pr20 m-pt40  '>
                    <Title>Tell us a bit about you</Title>
                    <div className='grid grid-cols-2 grid-rows-2 gap-x-40 gap-y-20'>
                        <FormField required={true} type="text" name="name" />
                        <FormField type="text" name="phone" />
                        <FormField required={true} type="email" name="email" />
                        <FormField type="text" name="position" />
                    </div>
                </div>
                <div className='bbw1 pb50 pt40 pl20 pr20'>
                    <Title>Tell us a bit about your business</Title>
                    <div className='grid grid-cols-2 grid-rows-2 gap-x-40 gap-y-20'>
                        <FormField required={true} type="text" name="businessName" />
                        <FormField type="text" name="website" />
                        <FormField type="text" name="city" />
                        <FormField type="text" name="country" />
                    </div>
                </div>
                <div className='bbw1 pb50 pt40 pl20 pr20'>
                    <Title>Do you have a design for this website? </Title>
                    <div className='grid grid-cols-2 gap-x-40 gap-y-20 '>
                        <SelectField options={yesNo} type="radio" name="hasDesign" />
                        <FormField type="text" name="figmaLink" />
                    </div>
                </div>
                <div className='bbw1 pb50 pt40 pl20 pr20'>
                    <Title>what is your ideal timeframe </Title>
                    <div className='grid grid-cols-2 gap-x-40 gap-y-20 '>
                        <FormField type="text" name="start" />
                        <FormField type="text" name="end" />
                    </div>
                </div>
                <div className='bbw1 pb50 pt40 pl20 pr20'>
                    <Title>where did you hear about us? </Title>
                    <FormField showTitle={false} type="text" name="referralInfo" />
                </div>
                <div className='bbw1 pl20 pr20 pb50 pt40 '>
                    <Title>do you have a budget in mind?</Title>
                    <SelectField type="radio" name="budget" options={budgetValues} />
                </div>
                <div className='bbw1 pl20 pr20 pb50 pt40 '>
                    <Title>anything else you’d like us to know about this project?</Title>
                    <FormField showTitle={false} type="text" name="additionalInfo" />
                </div>
                <div className='bbw1'>
                    <button type='submit' className='submit-button p20 white-50'>
                        <h2 className='text-16  m0'>
                            submit
                        </h2>
                    </button>
                </div>
            </Form>
            <TermsConditions />
        </div>
    )
}

function TermsConditions() {
    return (
        <div className='p20 m-pb80 '>
            <p className='white-50'>We respect the privacy of our customers and therefore have adopted a set of information management guidelines that are the foundation of our customer relationships. Read the policy...</p>
        </div>
    )
}