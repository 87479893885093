import React, { useState } from 'react'

import { animated, useChain, useSpring, useSpringRef, useTransition } from 'react-spring'
import { useMassOpen } from './context/tabs'
import Pill from './pill'



export default function HomeAnimation({ children }) {
    const [setOpen] = useMassOpen()
    const [hasAnimated, setHasAnimated] = useState(false)
    const [finishedAnimating, setFinishedAnimating] = useState(false)

    const transitionRef = useSpringRef()
    const transitions = useTransition(!hasAnimated, {
        from: { opacity: 1 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: {
            duration: 500
        },
        ref: transitionRef,
    })

    const tabRef = useSpringRef()
    const tabStyle = useSpring({
        opacity: (hasAnimated ? 1 : 0),
        config: {
            duration: 500
        },
        ref: tabRef,
    })


    const animatePillOutRef = useSpringRef()
    const animatePillOut = useTransition(!hasAnimated, {
        from: { opacity: 1 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        delay: 0,
        config: {
            duration: 250
        },
        ref: animatePillOutRef,
        onStart: () => {
            setOpen()
            setTimeout(function () {
                setFinishedAnimating(true)
                document.querySelector('.tabs.initial--state').classList.remove('initial--state');
            }, 1500);
        }
    })


    useChain([transitionRef, tabRef, animatePillOutRef], [0, 0.5, 1.5])

    return (
        <>
            {transitions(
                (styles, item) => item &&
                    <animated.div style={styles} className={' absoulte left-0 w-100vw bottom-20 z-100 flex justify-center align-center flex pointer-events-none'}>
                        <div className=' relative' style={styles}> <span>{`< Click to Enter />`}</span></div>
                    </animated.div >
            )}
            {animatePillOut(
                (styles, item) => item &&
                    <animated.button onClick={() => setHasAnimated(true)} style={styles} className='absoulte w-100 h-100 top-0 z-99'>
                        <Pill />
                    </animated.button>
            )}
            <animated.div style={tabStyle}>
                {children(finishedAnimating)}
            </animated.div>
        </>
    )
}



