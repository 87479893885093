import React from 'react'

export default function Hero({ className, maxWidth = '168px', color = '#3A3A3A', height= 'auto' }) {
    return (
        <svg className={className} style={{ maxWidth, width: '80%', height }} viewBox="0 0 168 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.4381 11.2808H13.7631C12.563 9.65825 10.5005 8.53789 8.02535 8.53789C3.37515 8.53789 0 12.3626 0 17.2689C0 22.1753 3.37515 26 8.02535 26C10.5005 26 12.563 24.8796 13.7631 23.2184H14.4381V25.4591H16.9132V1.77712H14.4381V11.2808ZM2.55011 17.2689C2.55011 13.6761 4.83771 10.8559 8.28786 10.8559C11.213 10.8559 13.2381 12.6716 14.4756 15.2214V19.2779C13.2381 21.8276 11.213 23.682 8.28786 23.682C4.83771 23.682 2.55011 20.8618 2.55011 17.2689Z" fill={color} />
            <path d="M31.136 5.06092C32.5611 5.06092 33.6111 3.9792 33.6111 2.58841C33.6111 1.12036 32.5611 0 31.136 0C29.786 0 28.6984 1.12036 28.6984 2.58841C28.6984 3.9792 29.786 5.06092 31.136 5.06092ZM32.8611 22.4844V9.07875H23.3357V11.2422H29.8235L30.611 12.0535V22.4844L29.8235 23.2957H23.3357V25.4591H39.5739V23.2957H33.6486L32.8611 22.4844Z" fill={color} />
            <path d="M53.9842 25.4591L62.122 9.07875H59.3469L53.0092 22.1753H52.1466L45.8088 9.07875H42.9962L51.0966 25.4591H53.9842Z" fill={color} />
            <path d="M74.2072 5.06092C75.6323 5.06092 76.6824 3.9792 76.6824 2.58841C76.6824 1.12036 75.6323 0 74.2072 0C72.8572 0 71.7696 1.12036 71.7696 2.58841C71.7696 3.9792 72.8572 5.06092 74.2072 5.06092ZM75.9323 22.4844V9.07875H66.4069V11.2422H72.8947L73.6822 12.0535V22.4844L72.8947 23.2957H66.4069V25.4591H82.6451V23.2957H76.7198L75.9323 22.4844Z" fill={color} />
            <path d="M98.1805 18.8529C99.7555 19.1233 100.881 19.5869 100.881 21.055C100.881 22.7935 98.5555 23.7207 95.9678 23.7207C93.0427 23.7207 90.7926 22.8707 88.505 20.6686L87.1175 22.948C89.5926 25.1114 92.7427 26 95.9304 26C99.9805 26 103.318 24.0683 103.318 20.9391C103.318 18.312 101.631 17.1144 99.0055 16.5736L93.4177 15.4918C91.5427 15.1441 90.3801 14.6419 90.3801 13.2511C90.3801 11.6672 92.3677 10.8172 94.8803 10.8172C97.5804 10.8172 99.6805 11.8217 101.556 13.6761L102.831 11.5513C100.768 9.42645 97.8429 8.53789 94.9928 8.53789C91.0551 8.53789 87.98 10.3536 87.98 13.367C87.98 16.1486 89.9676 17.2689 93.0052 17.8484L98.1805 18.8529Z" fill={color} />
            <path d="M117.278 5.06092C118.704 5.06092 119.754 3.9792 119.754 2.58841C119.754 1.12036 118.704 0 117.278 0C115.928 0 114.841 1.12036 114.841 2.58841C114.841 3.9792 115.928 5.06092 117.278 5.06092ZM119.004 22.4844V9.07875H109.478V11.2422H115.966L116.753 12.0535V22.4844L115.966 23.2957H109.478V25.4591H125.716V23.2957H119.791L119.004 22.4844Z" fill={color} />
            <path d="M129.889 17.2689C129.889 22.214 133.639 26 138.664 26C143.689 26 147.439 22.214 147.439 17.2689C147.439 12.3239 143.689 8.53789 138.664 8.53789C133.639 8.53789 129.889 12.3239 129.889 17.2689ZM144.889 17.2689C144.889 20.9004 142.339 23.682 138.664 23.682C134.989 23.682 132.439 20.9004 132.439 17.2689C132.439 13.6374 134.989 10.8559 138.664 10.8559C142.339 10.8559 144.889 13.6374 144.889 17.2689Z" fill={color} />
            <path d="M155.474 15.685C156.749 13.2897 158.775 10.8945 161.475 10.8945C164.4 10.8945 165.487 12.8648 165.487 15.7236V25.4591H168V15.26C168 10.9331 165.6 8.53789 161.775 8.53789C159.412 8.53789 157.35 9.89004 156.149 11.5899H155.474V9.07875H152.999V25.4591H155.474V15.685Z" fill={color} />
        </svg>

    )
}
