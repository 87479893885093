import React from 'react'
import { SwitchTransition, CSSTransition } from 'react-transition-group'
import { useTab } from './context/tabs'
import IfElse from './utils/else'
import useRunOnlyTabOpen from './hooks/useRunOnlyTabOpen'

const TIMING = 250


export default function Tab({ children, name, title = name, ratio, tabName, runIsOnly = () => { } }) {
    const [open, setOpen, length] = useTab(tabName)

    useRunOnlyTabOpen(open, length, runIsOnly)

    return (
        <div style={{ width: (open ? `${100 * ratio}%` : '60px'), minWidth: (open ? `0%` : `60px`) }} className={'h-100vh o-s  border-box p20 relative animate-width overflow-x-hidden overflow-y-scroll'} >
            <div className='h-100  w-100 whitespace ' >
                <SwitchTransition>
                    <CSSTransition
                        key={open}
                        classNames='fade'
                        timeout={TIMING}
                    >
                        <IfElse when={open} fallback={<Closed title={title} onClick={() => setOpen(true)} />}>
                            <Open onClick={() => setOpen(false)} name={name} >
                                {children}
                            </Open>
                        </IfElse>
                    </CSSTransition>
                </SwitchTransition>
            </div >
        </div >
    )
}


function Closed({ onClick, title }) {
    return (
        <button onClick={onClick} className='border-box h-100 flex  items-start  justify-center flex-col' >
            <div className='flex justify-between' >
                <div />
                <div className='absoulte right-20 top-20' >
                    <svg className='invert-svg-hover' width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="7.5" cy="7.5" r="7" stroke="#3A3A3A" />
                        <line x1="7.5" y1="11" x2="7.5" y2="4" stroke="#3A3A3A" />
                        <line x1="4" y1="7.5" x2="11" y2="7.5" stroke="#3A3A3A" />
                    </svg>
                </div>
            </div>
            {title}
        </button >
    )
}

function Open({ name, onClick, children }) {
    return (
        <div className=' border-box h-100 flex flex-col  '  >
            <button className='flex justify-between text-left' onClick={onClick}>
                <div className='title'>
                    {name}
                </div>
                <div className='absoulte right-20 top-20' >
                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" className='invert-svg-hover' xmlns="http://www.w3.org/2000/svg">
                        <circle cx="7.5" cy="7.5" r="7" stroke="#3A3A3A" />
                        <line x1="4" y1="7.5" x2="11" y2="7.5" stroke="#3A3A3A" />
                    </svg>
                </div>
            </button>
            {children}
        </div >
    )
}
