import { createPortal } from 'react-dom'

type PortalProps = {
    children: React.ReactNode;
    node: HTMLElement;
}

export function Portal({ children, node }: PortalProps) {
    return createPortal(
        children,
        node
    )
}