import React from 'react'

export default function Pill() {
    return (
        <div className='video--container z-2 relative h-100'>
           <video className='video' autoPlay loop muted playsInline>
              <source src="https://ik.imagekit.io/groundcrew/pill_7RdesP4rn.mov" type='video/mp4; codecs="hvc1"' />
              <source src='https://ik.imagekit.io/groundcrew/Untitled_MUJ6XAF-4.webm' type="video/webm" />
            </video>
        </div>
    )
}
