import React, { MouseEventHandler } from 'react'

type OverlayTabProps = {
    onClick: MouseEventHandler<HTMLButtonElement>
    title: string;
}

export default function OverlayTab({ onClick, title }: OverlayTabProps) {
    return (
        <button onClick={onClick} className='border-box h-100 flex  items-start  justify-center flex-col' >
            <div className='flex justify-between' >
                <div />
                <div className='absoulte right-20 top-20' >
                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" className='invert-svg-hover-dark' xmlns="http://www.w3.org/2000/svg">
                        <circle cx="7.5" cy="7.5" r="7" stroke="#efefef" />
                        <line x1="4" y1="7.5" x2="11" y2="7.5" stroke="#efefef" />
                    </svg>
                </div>
            </div>
            <div className='trasformText flex justify-center items-center font-med w-100 white'>
                {title}
            </div>
        </button >
    )
}
